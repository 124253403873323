.colBg{
    background-color: lightgray;
    margin: 10px 10px;
}
#mainContent {
    min-height: 100px !important;
}
.rowBg {
    background-color: white;
    margin: 12px 1px;
    padding: 5px;
}
.ddstyle {
    font-size: 19px;
}
.performacerow{
    padding: 5px;
    background-color: rgba(135, 206, 250, 0.301);
}
.performancerow2{
    display: flex;
    justify-content: space-between !important;
    margin: 2px 10px;
    padding: 6px;
    font-size: 15px;
    font-weight: bold;
    color: rgba(0, 0, 255, 0.534);
}
.performancerow3 {
    display: flex;
    justify-content: space-between !important;
    margin: 0px 10px;
    padding-left: 6px;
    padding-right: 6px;
}
.performancerow4{
    display: flex;
    justify-content: center !important;
    margin: 12px 10px;
}
.notificationrow{
    margin: 0px 10px;
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 10px;
}
.notificationrow1{
    margin: 2px 10px;
    padding: 10px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.404);
    border-radius: 10px;
}
#doubleline {
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
    margin-top: 12px;
}
#singleline {
    border-bottom: 1px solid grey;
    padding-bottom: 8px;
}
.tableitem{
    color: black;
    font-weight: normal;
    font-size: 14px;
}
.tableitembold{
    color: black;
    font-weight: bold;
    font-size: 14px;
}
.sideNavselling {
    display: flex;
    flex-direction: row;
}
.inputbord {
    border-color: black;
}
.headlines {
    border-top: 1px solid grey;
}
.wizardTitle {
    font-size: 16px !important;
}
.basicInfo {
    width: 70%; 
}
.red {
    margin-top: 12px;
    margin-bottom: 5px;
    font-weight: bold;
}
.basicInfo {
    display: flex;
    justify-content: center;
}
.imgUp {
    position: relative;
    margin: 5px;
}
.imgUp:hover img{
    opacity:0.9;
}
.imgUp button {
    position:absolute;
    /* display:none; */
    border: none !important;
    background-color: white;
}
.imgUp button {
    top:-60px;
    left:120px;
}
.column {
    max-height: 300px;
    overflow-y: scroll;
}
.costumRow {
    direction:ltr !important;
    width: 678px;
    overflow-x: auto;
}
